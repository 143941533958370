import { Link } from 'react-router-dom';


export default function Blog(){

    return(
    <>
    <main>
        <h1>BLOGS</h1><br/><br/>

        <Link to ="/blog/primerBlog"><h2>MI PRIMER BLOG</h2></Link>
        
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
    </main>
    </>);
}