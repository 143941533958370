const textStyle = {
    color: 'white', // Cambia a 'black' si quieres texto negro
    fontFamily: 'Arial, Verdana, sans-serif' // Cambia a 'Verdana' si prefieres esa fuente
    
  };

function FormEnviado() {
    return (
      <>
        <main>
          <h1 style={textStyle} id="h1AU">Muchas gracias por contactarnos✅</h1>
          <h3 style={textStyle} id="h1AU">Te contactaremos pronto😉</h3>
          <img id ="imgInicio5"src ="./imagenes/webDev5.jpg" alt ="imgForm"/><br/>
          <img id ="imgInicio5"src ="./imagenes/webDev6.jpg" alt ="imgForm"/><br/>
          <img id ="imgInicio5"src ="./imagenes/webDev7.png" alt ="imgForm"/><br/>
        </main>
      </>
    );
  }
  
  export default FormEnviado;
  