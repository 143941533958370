

export default function Inicio(){

    return (
    <>
    <main>
            <h1>BIENVENIDO A NUESTRA PAGINA WEB OFICIAL!</h1><br/><br/>
            <img id ="imgInicio" alt ="imagen Inicio" src="./imagenes/webDev.jpg"/><br/>
            <h1>ENVIANOS UN MENSAJE PARA COTIZAR TU PROYECTO!</h1><br/><br/>
            <img id ="imgInicio2" alt ="imagen Inicio" src="./imagenes/webDev2.jpg"/><br/>
            <h1>HACEMOS TUS SUEÑOS REALIDAD!😉</h1><br/><br/>
            <img id ="imgInicio3" alt ="imagen Inicio" src="./imagenes/webDev3.jpeg"/><br/>
            
        </main>
    </>);
    
}