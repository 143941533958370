

export default function AboutUs(){

    return (
        <>
        <main>
            <h1>SOBRE NOSOTROS</h1><br/><br/>
            <img id ="imgInicio4" alt="Imagen aboutUS" src="./imagenes/webDev4.jpg"/>
            <p>
                Somos una agencia de software especializada en desarrollo web para 
                nuevos negocios y PYMES que requieran una pagina web y asi tener
                presencia online, la diferencia con nosotros es que si desarrollamos
                tu pagina web no necesitas pagar web hosting, entregamos tu proyecto 
                en menos de 1 semana y cotizamos en precios accesibles! Lo cual reduce
                tus costos operativos, automatizas una actividad importante y ademas 
                impulsas tu negocio mas rapido! Somos la solucion ideal para tu sitio web
            </p><br/>

            <h1>MISION</h1><br/>
            <p>
                Ayudar a los emprendedores automatizando y mejorando el sitio web
                de sus negocios
            </p><br/>

            <h1>VISION</h1><br/>
            <p>
                Ser alguien de confianza para los nuevos negocios
                y empresas establecidas
            </p><br/>

            <h1>VALORES</h1><br/>
            <p>
                Confianza, Lealtad y Honestidad
            </p><br/>
            
     
        </main>
        </>);
    
}