
function Header(){

    return(

        <header>
            <img alt ="" src ="./imagenes/newLogo2.jpg" width = "150" height = "150"/>
            <h1 id = "h1Index">TECNOLOGIAS ESTRADA</h1>
        </header>
    
    );

}

export default Header