import {Helmet} from 'react-helmet-async';

export default function PrimerBlog(){

    return(
    <>
    <Helmet>
        <title>Mi Primer Blog</title>
        <meta name="description" content="Este es mi primer blog en React"/>
        <link rel="canonical" href="/blog/primerBlog"/>
    </Helmet>
        <main>
        <h1>MI PRIMER BLOG</h1><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Sed eros erat, ornare eget auctor in, condimentum eget eros. 
        Morbi molestie tempor erat vitae fermentum. In suscipit dictum ligula.
        Nulla augue nisl, rutrum et iaculis semper, varius eu velit. 
        </p><br/>
    </main>
    </>);
}